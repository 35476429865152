<template>
    <div class="view pa24">
        <div>
            <el-tabs v-model="webType">
                <el-tab-pane label="分销提现记录" name="memberWithdrawal"></el-tab-pane>
                <el-tab-pane label="推广提现记录" name="activityWithdrawal"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType==='activityWithdrawal'">
            <activity-withdrawal/>
        </div>
        <div v-if="webType==='memberWithdrawal'">
            <member-withdrawal/>
        </div>
    </div>
</template>
  
<script>
import activityWithdrawal from "./pages/activityWithdrawal";
import memberWithdrawal from "./pages/memberWithdrawal";
export default {
    name: "orderList",
    components: {
        activityWithdrawal,
        memberWithdrawal
    },
    data() {
        return {
            webType: "memberWithdrawal",
        };
    },
};
</script>
  
<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>