import httpUtil from "@/utils/httpUtil";

/**获取分销提现记录列表 */
export const getMemberWithdrawalsRecordList = async params => httpUtil.post("/api/crmPc/member/getMemberWithdrawalsRecordList", params)

/**获取分销提现记录明细列表 */
export const getMemberDistributorRecordList = async params => httpUtil.post("/api/crmPc/member/getMemberDistributorRecordList", params)

/**修改分销提现记录列表 用于审核*/
export const updateMemberWithdrawalsRecord = async params => httpUtil.post("/api/crmPc/member/updateMemberWithdrawalsRecord", params)

/**活动推广提现记录列表 */
export const getActivityShareWithdrawalsList = async params => httpUtil.post("/api/crmPc/activityEvent/getActivityShareWithdrawalsList", params)

/**修改分销提现记录列表 用于审核*/
export const updateActivityShareWithdrawals = async params => httpUtil.post("/api/crmPc/activityEvent/updateActivityShareWithdrawals", params)

/**获取手续费*/
export const getAccountFee = async params => httpUtil.post("/api/crmPc/companyAccount/getAccountFee", params)

/**设置手续费 */
export const setAccountFee = async params => httpUtil.post("/api/crmPc/companyAccount/setAccountFee", params)